import { Suspense, lazy, useEffect, useState } from "react";

import VfLogo from './utils/vfLogo';

const Widget = ({ token }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [Component, setComponent] = useState();

    const applyStyle = (css) =>{
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';

        if (style.styleSheet){
            style.styleSheet.cssText = css; //for older browsers
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
    }

    const getData = async () =>{

        try{
            console.log(`Pulling data from: ${token}`);
            // const res = await fetch(`http://localhost:3002/widget/${token}`);
            const res = await fetch(`https://www.videoflow.io/widget/${token}`);
            if (res.ok) {
                const resData = await res.json();
                console.log(resData);
                setData(resData);

                if(resData.style){
                    applyStyle(resData.style);
                }

                const w = lazy(()=> import(`./widgets/${resData.type}/${resData.type}.js`));
                setComponent(w);

                setLoading(false);
            } else {
                setLoading(false);
                setError(`Error ${res.status}: ${res.statusText}`);
            }
        }catch(err){
            setLoading(false);
            setError("Content is not available");
        }
    }

    useEffect(()=>{
        getData();
    },[]);

    if(loading){
        return (
            <div className="w-full h-full flex justify-center items-center py-2">
              <div className="bg-black/90 p-2 rounded-md">
                <span className="text-white animate-pulse">Loading...</span>
              </div>
            </div>
        );
    }else if (error){
        return(<></>
        // <div className="w-full h-full flex justify-center items-center py-2">
        //     <div className="bg-black/90 p-2 rounded-md">
        //       <span className="text-white">{error}</span>
        //     </div>
        //   </div>
        );
    }else{
    return(
        <Suspense fallback={null}>
            <Component data={data} />
        </Suspense>
        );
    }
}

export default Widget;
